import React, { Component } from "react";
import Fade from "react-reveal";

class About extends Component {
  render() {

    const profilepic = "images/1616428397362.jfif";
    const phone = '+1-571-275-8568';
    const resumeDownload = "https://github.com/Iberassa";
    

    return (
      <section id="about">
        <Fade duration={1000}>
          <div className="row">
            <div className="three columns">
              <img
                className="profile-pic"
                src={profilepic}
                alt="Profile Pic"
              />
            </div>
            <div className="nine columns main-col">
              <h2>About Me</h2>

              <p>I am a full stack JavaScript developer that creates front end website architecture, 
                design user interactions on web pages, develop back end website applications and create 
                servers and databases for functionality using different programing languages like
                JavaScript, HTML, Nodejs, Express, MongoDB, React, Angular, SQL, Web Services and more.</p>
              <div className="row">
                <div className="columns contact-details">
                  <h2>Contact Details</h2>
                  <p className="address">
                    <span>Indrias Berassa</span>
                    <br />
                    <span>
                      Alexandria, VA USA
                    </span>
                    <br />
                    <span>{phone}</span>
                    <br />
                    <span>indriasbelachew@gmail.com</span>
                  </p>
                </div>
                <div className="columns download">
                  <p>
                    <a href={resumeDownload} className="button">
                      <i className="fa fa-download"></i>Download Resume
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </section>
    );
  }
}

export default About;
