import React, { Component } from "react";
import Fade from "react-reveal";

class Footer extends Component {
  state = {
    social: [{ className: "fa fa-facebook", name: "facebook", url: "https://github.com/Iberassa" },
    { className: "fa fa-twitter", name: "twitter", url: "http://twitter.com" },
    { className: "fa fa-linkedin", name: "linkedin", url: "https://www.linkedin.com/in/indrias-berassa-22ab60209/" },
    { className: "fa fa-instagram", name: "instagram", url: "http://instagram.com" },
    { className: "fa fa-github", name: "github", url: "https://github.com/nordicgiant2/react-nice-resume" }]
  }
  render() {
    const networks = this.state.social.map(function (network) {
      return (
        <li key={network.name}>
          <a href={network.url}>
            <i className={network.className}></i>
          </a>
        </li>
      );
    });

    return (
      <footer>
        <div className="row">
          <Fade bottom>
            <div className="twelve columns">
              <ul className="social-links">{networks}</ul>

              <ul className="copyright">
                <li>&copy; Copyright 2021 Nordic Giant</li>
                <li>
                  Design by{" "}
                  <a title="Styleshout" href="http://www.styleshout.com/">
                    Styleshout
                  </a>
                </li>
              </ul>
            </div>
          </Fade>

          <div id="go-top">
            <a className="smoothscroll" title="Back to Top" href="#home">
              <i className="icon-up-open"></i>
            </a>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
