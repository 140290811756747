import React, { Component } from "react";
import { Fade, Slide } from "react-reveal";
const axios = require('axios');

class Contact extends Component {
  state = {
    name: '',
    email: '',
    subject: '',
    content: '',
    phone: '',
    submitBut: false,
    count: 0
  }

  handleChange = (e) => {
    this.setState((state) => {
      return { ...state, [e.target.name]: e.target.value }
    })
    if (this.state.email && this.state.content && this.state.name && this.state.subject && this.state.phone && this.state.count === 0) {
      this.setState((state) => { return { ...state, submitBut: true, count: this.count + 1 } })
    }
  }




  submit = () => {
    const sentMessage = {
      name: this.state.name,
      email: this.state.email,
      subject: this.state.subject,
      content: this.state.content,
      phone: this.state.phone,
    }
    this.setState({ name: '', email: '', subject: '', content: '', phone: '', submitBut: false })

    axios.post('https://ccufd4x23c.execute-api.us-east-1.amazonaws.com/v1/contact', sentMessage)
      .then((response) => {
        if (response.status === 200)
          alert('Your message was sent, thank you!');
      })
      .catch((err) => {
        alert(err.status+ ' Please try again later.');
      })

    console.log(sentMessage);
  }


  render() {

    const name = "Indrias Berassa";
    const city = "Alexandria";
    const state = "VA";
    const zip = "";
    const phone = "+1-571-275-8568";

    return (
      <section id="contact">
        <Fade bottom duration={1000}>
          <div className="row section-head">
            <div className="two columns header-col">
              <h1>
                <span>Get In Touch.</span>
              </h1>
            </div>

            <div className="ten columns">
              <p className="lead">Get In Touch</p>
            </div>
          </div>
        </Fade>

        <div className="row">
          <Slide left duration={1000}>
            <div className="eight columns">
              {/* <form action="" method="post" id="contactForm" name="contactForm"> */}
              <fieldset>
                <div>
                  <label htmlFor="contactName">
                    Name <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    value={this.state.name}
                    size="35"
                    id="contactName"
                    name="name"
                    onChange={this.handleChange}
                  />
                </div>

                <div>
                  <label htmlFor="contactEmail">
                    Email <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    value={this.state.email}
                    size="35"
                    id="contactEmail"
                    name="email"
                    onChange={this.handleChange}
                  />
                </div>

                <div>
                  <label htmlFor="contactEmail">
                    Phone Number <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    value={this.state.phone}
                    size="35"
                    id="contactPhone"
                    name="phone"
                    onChange={this.handleChange}
                  />
                </div>

                <div>
                  <label htmlFor="contactSubject">
                    Subject <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    value={this.state.subject}
                    size="35"
                    id="contactSubject"
                    name="subject"
                    onChange={this.handleChange}
                  />
                </div>

                <div>
                  <label htmlFor="contactMessage">
                    Message <span className="required">*</span>
                  </label>
                  <textarea
                    cols="50"
                    rows="15"
                    id="contactMessage"
                    value={this.state.content}
                    name="content"
                    onChange={this.handleChange}
                  ></textarea>
                </div>

                <div>
                  {this.state.submitBut && <button className="submit" onClick={this.submit} >Send</button>}
                  <span id="image-loader">
                    <img alt="" src="images/loader.gif" />
                  </span>
                </div>
              </fieldset>
              {/* </form> */}

              <div id="message-warning"> Error boy</div>
              <div id="message-success">
                <i className="fa fa-check"></i>Your message was sent, thank you!
                <br />
              </div>
            </div>
          </Slide>

          <Slide right duration={1000}>
            <aside className="four columns footer-widgets">
              <div className="widget widget_contact">
                <h4>Address and Phone</h4>
                <p className="address">
                  {name} <br />
                  {city}, {state} {zip}
                  <br />
                  <span>{phone}</span>
                </p>
              </div>
            </aside>
          </Slide>
        </div>
      </section>
    );
  }
}

export default Contact;
